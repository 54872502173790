const eliteParkingLogo = '/logos/elite-logo.png'
const spacesLogo = '/logos/spaces-logo.png'
const gmpLogo = '/logos/gmp-logo-white.png'
const apcoaLogo = '/logos/apcoa-logo.png'
const gmpFevicon = '/logos/gmp-favicon.ico'
const apcoaFevicon = '/logos/apcoa-favicon.ico'
const oneparkingFavicon = '/logos/oneparking-favicon.png'
const oneparkingLogo = '/logos/oneparking-logo.png'
const garageinnLogo = '/logos/garageinn-logo.png'
const garageinnFavicon = '/logos/garageinn-fevicon.png'
const greenarkingFevicon = '/logos/greenparking-fevicon.png'
const greenparkingLogo = '/logos/greenparking-logo.png'
const bacFavicon = '/logos/bac-favicon.png.png'
const bacLogo = '/logos/bac-logo.png'
const towneParkLogo = '/logos/townepark-logo.png'

const defaultThemeValues = {
	themeColor: '#1890ff',
	icon: gmpFevicon,
	logoUrl: gmpLogo,
}

const configs = [
	{
		slug: 'apcoa',
		title: 'Apcoa',
		themeColor: '#78b51b',
		logoUrl: apcoaLogo,
		icon: apcoaFevicon,
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.flow-connect.net',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.apcoaflow.com',
			PREPROD: 'https://preprod.flow-connect.net',
			'STAGING-EU': 'https://test.parkingplusmore.com',
		},
		SOCKET_URL: {
			DEVELOPMENT: 'wss://khipy1tvzb.execute-api.ap-southeast-1.amazonaws.com',
			STAGING: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			QA: 'wss://5gov7qm5b1.execute-api.ap-south-1.amazonaws.com/ApiQA',
			PRODUCTION: 'wss://9asyfohft1.execute-api.eu-central-1.amazonaws.com/APIproduction',
			PREPROD: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
		},
	},
	{
		slug: 'gmp',
		title: 'Get My Parking',
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.parkingglobalserver.com',
			'PRODUCTION-US': 'https://api.parkingglobalserver.com',
		},
		SOCKET_URL: {
			DEVELOPMENT: 'wss://khipy1tvzb.execute-api.ap-southeast-1.amazonaws.com',
			STAGING: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			QA: 'wss://5gov7qm5b1.execute-api.ap-south-1.amazonaws.com/ApiQA',
			PRODUCTION: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			PREPROD: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
		},
	},
	{
		slug: 'gmp-prod-me',
		title: 'Get My Parking',
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api-greenparking.parkingglobalserver.com',
			'PRODUCTION-US': 'https://api.parkingglobalserver.com',
		},
		SOCKET_URL: {
			DEVELOPMENT: 'wss://khipy1tvzb.execute-api.ap-southeast-1.amazonaws.com',
			STAGING: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			QA: 'wss://5gov7qm5b1.execute-api.ap-south-1.amazonaws.com/ApiQA',
			PRODUCTION: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			PREPROD: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
		},
	},
	{
		slug: 'gmp_dev',
		title: 'Get My Parking',
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.parkingglobalserver.com',
			'PRODUCTION-US': 'https://api.parkingglobalserver.com',
		},
		SOCKET_URL: {
			DEVELOPMENT: 'wss://khipy1tvzb.execute-api.ap-southeast-1.amazonaws.com',
			STAGING: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			QA: 'wss://5gov7qm5b1.execute-api.ap-south-1.amazonaws.com/ApiQA',
			PRODUCTION: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			PREPROD: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
		},
	},
	{
		slug: 'hmrl',
		title: 'HMRL',
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.parkingglobalserver.com',
			'PRODUCTION-US': 'https://api.parkingglobalserver.com',
		},
		SOCKET_URL: {
			DEVELOPMENT: 'wss://khipy1tvzb.execute-api.ap-southeast-1.amazonaws.com',
			STAGING: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			QA: 'wss://5gov7qm5b1.execute-api.ap-south-1.amazonaws.com/ApiQA',
			PRODUCTION: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			PREPROD: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
		},
	},
	{
		slug: 'apcoapilot',
		title: 'Apcoa Pilot',
		themeColor: '#78b51b',
		logoUrl: apcoaLogo,
		icon: apcoaFevicon,
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.flow-connect.net',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.apcoaflow.com',
		},
		SOCKET_URL: {
			DEVELOPMENT: 'wss://khipy1tvzb.execute-api.ap-southeast-1.amazonaws.com',
			STAGING: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			QA: 'wss://5gov7qm5b1.execute-api.ap-south-1.amazonaws.com/ApiQA',
			PRODUCTION: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			PREPROD: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
		},
	},
	{
		slug: 'gmppilot',
		title: 'Pilot Get My Parking',
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.parkingglobalserver.com',
			'PRODUCTION-US': 'https://api.parkingglobalserver.com',
		},
		SOCKET_URL: {
			DEVELOPMENT: 'wss://khipy1tvzb.execute-api.ap-southeast-1.amazonaws.com',
			STAGING: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			QA: 'wss://5gov7qm5b1.execute-api.ap-south-1.amazonaws.com/ApiQA',
			PRODUCTION: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			PREPROD: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
		},
	},
	{
		slug: 'spaces',
		title: 'Spaces',
		themeColor: '#FAD41F',
		logoUrl: spacesLogo,
		icon: spacesLogo,
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'http://space-staging-load-balancer-918855242.us-east-1.elb.amazonaws.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.spacesusa.com',
		},
		SOCKET_URL: {
			DEVELOPMENT: 'wss://khipy1tvzb.execute-api.ap-southeast-1.amazonaws.com',
			STAGING: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			QA: 'wss://5gov7qm5b1.execute-api.ap-south-1.amazonaws.com/ApiQA',
			PRODUCTION: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			PREPROD: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
		},
	},
	{
		slug: 'eliteparking',
		title: 'Elite Parking',
		themeColor: '#F32735',
		logoUrl: eliteParkingLogo,
		icon: eliteParkingLogo,
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.elitevipallaccess.com',
		},
		SOCKET_URL: {
			DEVELOPMENT: 'wss://khipy1tvzb.execute-api.ap-southeast-1.amazonaws.com',
			STAGING: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			QA: 'wss://5gov7qm5b1.execute-api.ap-south-1.amazonaws.com/ApiQA',
			PRODUCTION: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			PREPROD: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
		},
	},
	{
		slug: 'oneparking',
		title: 'One Parking',
		themeColor: '#F2CC39',
		logoUrl: oneparkingLogo,
		icon: oneparkingFavicon,
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.parkingglobalserver.com',
			'PRODUCTION-US': 'https://api.parkingglobalserver.com',
		},
		SOCKET_URL: {
			DEVELOPMENT: 'wss://khipy1tvzb.execute-api.ap-southeast-1.amazonaws.com',
			STAGING: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			QA: 'wss://5gov7qm5b1.execute-api.ap-south-1.amazonaws.com/ApiQA',
			PRODUCTION: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			PREPROD: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
		},
	},
	{
		slug: 'garageinn',
		title: 'Garage Inn',
		themeColor: '#FF0000',
		logoUrl: garageinnLogo,
		icon: garageinnFavicon,
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.parkingglobalserver.com',
			'PRODUCTION-US': 'https://api.parkingglobalserver.com',
		},
		SOCKET_URL: {
			DEVELOPMENT: 'wss://khipy1tvzb.execute-api.ap-southeast-1.amazonaws.com',
			STAGING: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			QA: 'wss://5gov7qm5b1.execute-api.ap-south-1.amazonaws.com/ApiQA',
			PRODUCTION: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			PREPROD: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
		},
	},
	{
		slug: 'greenparking',
		title: 'Green Parking',
		themeColor: '#3DA540',
		logoUrl: greenparkingLogo,
		icon: greenarkingFevicon,
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.parkingglobalserver.com',
			'PRODUCTION-US': 'https://api.parkingglobalserver.com',
		},
		SOCKET_URL: {
			DEVELOPMENT: 'wss://khipy1tvzb.execute-api.ap-southeast-1.amazonaws.com',
			STAGING: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			QA: 'wss://5gov7qm5b1.execute-api.ap-south-1.amazonaws.com/ApiQA',
			PRODUCTION: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			PREPROD: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
		},
	},
	{
		slug: 'bac',
		title: 'BAC',
		themeColor: '#001d34',
		logoUrl: bacLogo,
		icon: bacFavicon,
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.parkingglobalserver.com',
			'PRODUCTION-US': 'https://api.parkingglobalserver.com',
		},
		SOCKET_URL: {
			DEVELOPMENT: 'wss://khipy1tvzb.execute-api.ap-southeast-1.amazonaws.com',
			STAGING: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			QA: 'wss://5gov7qm5b1.execute-api.ap-south-1.amazonaws.com/ApiQA',
			PRODUCTION: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			PREPROD: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
		},
	},
	{
		slug: 'wilson',
		title: 'WILSON',
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.parkingglobalserver.com',
			'PRODUCTION-US': 'https://api.parkingglobalserver.com',
		},
		SOCKET_URL: {
			DEVELOPMENT: 'wss://khipy1tvzb.execute-api.ap-southeast-1.amazonaws.com',
			STAGING: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			QA: 'wss://5gov7qm5b1.execute-api.ap-south-1.amazonaws.com/ApiQA',
			PRODUCTION: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			PREPROD: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
		},
	},
	{
		slug: 'townepark',
		title: 'Towne Park',
		themeColor: '#F32735',
		logoUrl: towneParkLogo,
		icon: towneParkLogo,
		API_URL: {
			DEVELOPMENT: 'https://dev.getsmartparking.com',
			STAGING: 'https://staging.parkingplusmore.com',
			QA: 'https://qa.getmyparking.com',
			PRODUCTION: 'https://api.elitevipallaccess.com',
		},
		SOCKET_URL: {
			DEVELOPMENT: 'wss://khipy1tvzb.execute-api.ap-southeast-1.amazonaws.com',
			STAGING: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			QA: 'wss://5gov7qm5b1.execute-api.ap-south-1.amazonaws.com/ApiQA',
			PRODUCTION: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
			PREPROD: 'wss://i2a8x6mwmj.execute-api.eu-central-1.amazonaws.com/APIstaging',
		},
	},
]

const { REACT_APP_TENANT: tenant, REACT_APP_DEPLOYMENT: deployment, REACT_APP_HMAC_SALT: salt } = process.env

if (!tenant) {
	console.error(`Tenant not defined`)
}

if (!deployment) {
	console.error(`Deployment not defined`)
}

let configData = configs.find(o => o.slug === tenant.toLowerCase())

configData = {
	...defaultThemeValues,
	...configData,
	API_URL: configData.API_URL[deployment],
	SOCKET_URL: configData.SOCKET_URL[deployment],
	deployment,
	salt,
}

export default configData
