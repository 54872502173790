import mqtt from 'mqtt'

class Mqtt {
    constructor(){
        this.client = null
        this.topic = 'uiLogs'
    }

    connectToBroker = (url, options) => {
        try{
            if(!this.client){
                const clientId = 'mqttjs_' + Math.random().toString(16).substr(2, 8)
                this.client = mqtt.connect(url, {...options, clientId})
                this.client.on('error', (e)=>{
                    console.log(`Connection error: ${e}`);
                })
                
                this.client.on('connect', ()=>{
                    console.log('Connected to mqtt broker ', this.client);
                })
                /*
                // subscribing to the topic and listening for the published messages
                this.client.subscribe('dashboard')
                this.client.on('message', (topic, message, packet) => {
                    console.log('message from broker', message.toString())
                  })
                */     
            }
        } catch(e){
            console.error(e)
        }

    }
    
    publishToBroker = (message) => {
        try{
            if(this.client && message){
                this.client.publish(this.topic, message)
            }
        } catch(e){
            console.error(e)
        }     
    }

    disConnectFromBroker = () =>{
        try{
            this.client.end(true)
        } catch(e){
            
        }
    }

}

export default new Mqtt()