import React, { Component } from 'react'
import { Result, Button, Icon, Typography } from 'antd'
import styled from 'styled-components'
import mqtt from '@utils/mqtt'


const { Paragraph, Text } = Typography

const ErrorPage = styled(Result)`
	background-color: white;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	min-height: 100vh;
`

class ErrorBoundary extends Component {
	constructor(props) {
		super(props)
		this.state = { error: null, errorInfo: null, eventId: null }
	}

	componentDidCatch(error, errorInfo) {
        mqtt.publishToBroker(String(error) + String(errorInfo.componentStack))

		this.setState({
			error: error,
			errorInfo: errorInfo,
		})
	}

	onClickHandler() {
		document.location.href = '/'
	}

	render() {
		if (this.state.errorInfo) {
			return (
				<ErrorPage
					status='error'
					title='Something Went Wrong'
					subTitle='Please retry.'
					extra={[
						<Button type='primary' key='console' onClick={this.onClickHandler} data-testid="ErrorBoundary_8fed0_ErrorPage">

							Go Home
						</Button>,
					]}
				>
					<div className='desc' data-testid="ErrorBoundary_d3728_div">

						<Paragraph data-testid="ErrorBoundary_c5176_Paragraph">

							<Text
								strong
								style={{
									fontSize: 16,
								}}
							 data-testid="ErrorBoundary_f25ef_Text">

								The program encountered the following error :
							</Text>
						</Paragraph>
						<Paragraph data-testid="ErrorBoundary_b9fcc_Paragraph">

							<Icon style={{ color: 'red' }} type='close-circle'  data-testid="ErrorBoundary_0b719_Icon" /> {this.state.error && this.state.error.toString()}

						</Paragraph>
					</div>
				</ErrorPage>
			)
		}

		return this.props.children
	}
}

export default ErrorBoundary

