const authentication = '/authentication/v1'
const suffix = '/'

const dashboardBase = authentication + '/dashboard'
const dashboard = {
	BASE: dashboardBase,
	AUDIT_LOG: dashboardBase + '/auditLog/module',
	AUDIT_LOG_USER: dashboardBase + '/auditLog/userActivity',
	PARKING: dashboardBase + '/parking',
	CONSUMER: dashboardBase + '/consumer',
	CONSUMER_SEARCH: dashboardBase + '/consumer/search',
	CONSUMER_ADDRESS: dashboardBase + '/consumer/address',
	CONSUMER_RFID: dashboardBase + '/consumer/rfid',
	CONSUMER_UI: dashboardBase + '/consumerUserIdentification',
	PARKING_SESSION: dashboardBase + '/parkingSession',
	COMPANY: dashboardBase + '/company/locationCodes',
	VAS: dashboardBase + '/vas/allocatedData',
	FILTER: dashboardBase + '/filter',
	OFFER: dashboardBase + '/Offer',
	OFFER_SUB: dashboardBase + '/Offer/subCorporate',
	ENABLE_VERSION: dashboardBase + '/enableVersionManagement',
	OTP: dashboardBase + '/generateOTP',
	ENABLE_SYSTEM: dashboardBase + '/enableVersionManagement/enableSystem',
	ENABLE_CONFIG: dashboardBase + '/enableConfig',
	REVERSE_PERMISSION: dashboardBase + '/reverse/permission',
	SESSION_LOG: dashboardBase + '/sessionLogs',
	ENABLE_TRANSACTION: dashboardBase + '/enable/transactions',
	ENABLE_EVENTS: dashboardBase + '/enable/events',
	ENABLE_PERMISSION: dashboardBase + '/enable/permission',
	CONSUMER_SESSION: dashboardBase + '/consumerSession/forceClose',
	PERMISSION_MASTER: dashboardBase + '/permissionMaster',
	GRAFANA_DETAILS: dashboardBase + '/grafanaDetails',
	PARKING_SESSION_IMAGE: dashboardBase + '/parkingSessionImage',
	TEMPLATE_TYPES: dashboardBase + '/communication/templates/types',
	COMM_COMPANY: dashboardBase + '/communication/company',
	TENANT: dashboardBase + '/tenant',
}

const reportBase = authentication + '/report'
const report = {
	BASE: reportBase,
	PARKING_LOG: reportBase + '/parkingLogs',
	SESSION: reportBase + '/session',
	DATA_DUMP: reportBase + '/dataDump',
	LIVE_SESSION: reportBase + '/liveSessionReport',
	// REVENUE: reportBase + '/revenue',
	ANOMALY: reportBase + '/anamoly',
}

const paymentBase = authentication + '/payment'
const payment = {
	TRANS_DETAILS: paymentBase + '/transaction/transactionDetails',
	TRANS_HISTORY: paymentBase + '/transaction/history',
	REFUND: paymentBase + '/refund',
	DOWNLOAD_INVOICE: paymentBase + '/operator/downloadInvoice',
}

const paymentsBase = authentication + '/payments'
const payments = {
	GATEWAY: paymentsBase + '/gateway',
	GATEWAY_ATTRIBUTE: paymentsBase + '/gateway/attribute',
}
const discount = {
	DISCOUNT_CARDS: authentication + '/discountCards/getAllV2',
}

const consumerBase = authentication + '/consumer'
const consumer = {
	BASE: consumerBase,
	VEHICLE: consumerBase + '/vehicle',
	DASHBOARD_SIGNUP: consumerBase + '/dashboard/signUp',
	PAYMENT: consumerBase + '/payment',

	PARKING_SESSION: consumerBase + '/parkingSession',
	PARKING_SESSION_BREAK: consumerBase + '/parkingSession/breakupsById/bulk',
	INDENTIFICATION: consumerBase + '/identification',
	DEVICE: consumerBase + '/device',
	BULK_ON_BOARD: consumerBase + '/corporate/bulkOnBoardFromCsv',
	UPDATE_BULK_ON_BOARD: consumerBase + '/corporate/updateBulkOnBoardFromCsv',
	CORPORATE_LIST: consumerBase + '/corporate/list',
	CORPORATE_LIST_SEARCH: consumerBase + '/corporate/list/search',
	BULK_ENABLED: consumerBase + '/corporate/bulkEnableCorporateAccounts',
	BULK_DISABLED: consumerBase + '/corporate/bulkDisableCorporateAccounts',
	BULK_LINK: consumerBase + '/corporate/bulkLinkCorporateAccounts',
	BULK_DELINK: consumerBase + '/corporate/bulkDelinkCorporateAccounts',
	CORPORATE_USER: consumerBase + '/corporate/createCorporateUser',
	UPDATE_CORPORATE_USER: consumerBase + '/corporate/updateCorporateUser',
	ON_BOARD_SINGLE_USER: consumerBase + '/corporate/onBoardSingleUser',
	VERIFY_RFID: consumerBase + '/rfidOrder/verifyRfid',
	LINK_RFID: consumerBase + '/rfidOrder/linkRfid',
	DELINK_RFID: consumerBase + '/rfidOrder/deLinkRfid',
	PARKING_SESSION_COUNT: consumerBase + '/parkingSession/sessionCount',
}

const consumersBase = authentication + '/consumers'
const consumers = {
	BASE: consumersBase,
	ACCOUNT_GROUPS: consumersBase + '/accountGroups',
	GROUP_ID: consumersBase + '/accountGroups/byGroupId',
	ADD_GROUPS: consumersBase + '/accountGroups/addAccountGroup',
	GROUP_ACCOUNT: consumersBase + '/accountGroups/groupAccount',
	DOCUMENTS: consumersBase + '/documents',
}

const passBase = authentication + '/pass'
const pass = {
	BASE: passBase,
	PASS_ALLOCATIONS: passBase + '/dashboard/passAllocations',
	PASS_ALLOCATE: passBase + '/passAllocate',
	PASS_MASTER: passBase + '/passMaster',
	PASS_MASTER_ACCESS: passBase + '/passMasterAccess',
	CORPORATE_PASS_ALLOCATE: passBase + '/corporate/passAllocate',
	PASS_ACTIVITY: passBase + '/passAllocation/activity',
}
const parkingBase = authentication + '/parking'
const parking = {
	BASE: parkingBase,
	ACCESS_METHODS: parkingBase + '/accessMethods',
	HARDWARE_MASTER: parkingBase + '/hardwareMaster',
	DEVICE_DETAILS: parkingBase + '/deviceDetails',
	PARKING_IMG: parkingBase + '/parkingImages',
	UPDATE_IMG: parkingBase + '/bulkUpdateParkingImages',
	UPLOAD_IMG: parkingBase + '/bulkUploadParkingImages',
	ACCESS_TYPE_MASTER: parkingBase + '/accessTypeMaster/list',
}

const tariffBase = authentication + '/tariff'
const tariff = {
	BASE: tariffBase,
	LIST: tariffBase + '/list',
	CALCULATE: tariffBase + '/calculate',
	DATE_BY_ID: tariffBase + '/dataByIds',
	TEST_TARIFF: tariffBase + '/testTariff',
	TARIFF_AUTOMATIOM: tariffBase + '/testTariff/automation',
	FETCH_LIVERATE: tariffBase + '/fetchTariffRates'
}

const promotionBase = authentication + '/promotion'
const promotion = {
	BASE: promotionBase,
	OFFER_BY_ID: promotionBase + '/byOfferId',
	CREATE: promotionBase + '/create',
	BULD_CREATE: promotionBase + '/bulk/create',
	BULK_UPLOAD: promotionBase + '/bulkUpload/csv',
	EXPIRE: promotionBase + '/expire',
}

const operatorBase = authentication + '/operator'
const operator = {
	BASE: operatorBase,
	LOGIN: operatorBase + '/login',
	LOGOUT: operatorBase + '/logout',
	SIGNUP: operatorBase + '/signUp',
	LIST: operatorBase + '/list',
	ACCESS: operatorBase + '/access',
	PERMISSION: operatorBase + '/permission',
	CHANGE_PASSWORD: operatorBase + '/changePassword',
}

const monitorBase = authentication + '/monitor'
const monitor = {
	BASE: monitorBase,
	ALERT_CONFIG: monitorBase + '/alertConfiguration',
	ALERT_PREFERNCES: monitorBase + '/alertPreferences',
}

const sponsoredAdsBase = dashboardBase + '/inAppSponsorAds'
const sponsoredAds = {
	BASE: sponsoredAdsBase,
	FETCHPROMO: sponsoredAdsBase + '/getInAppSponsorAdsDetails',
	CREATEPROMO: sponsoredAdsBase + '/createInAppSponsorAds',
	UPDATEPROMO: sponsoredAdsBase + '/updateInAppSponsorAds',
	PRESIGNEDURL: sponsoredAdsBase + '/getPreSignedUploadImageUrl',
}
const validateOnlinePayment = authentication + '/gmp-billing/core'

export default {
	BASE: authentication,
	LOGIN: authentication + '/operator/login' + suffix,
	GRAPHQL_OPERATOR: authentication + '/permit/graphql/operator' + suffix,
	MASTER_CONFIG: authentication + '/company/companyAttribute',
	BULK_UPLOAD: authentication + '/permit/migration/bulkAllocationFromCSV',
	DASHBOARD: dashboard,
	REPORT: report,
	PAYMENT: payment,
	PAYMENTS: payments,
	CONSUMER: consumer,
	DISCOUNT: discount,
	CONSUMERS: consumers,
	PASS: pass,
	PARKING: parking,
	SEARCH: authentication + '/search',
	CORPORATE: authentication + '/corporate',
	PARKING_LOT: authentication + '/parkingLot',
	TARIFF: tariff,
	PROMOTION: promotion,
	LANES: authentication + '/lanes',
	OPERATOR: operator,
	VALUE_ADDRESS_SERVICE: authentication + '/valueAddedServices',
	GATE_CONTROLLER: authentication + '/gateController',
	SUB_CORPORATE: authentication + '/subCorporate',
	MONITOR: monitor,
	COMPANY: authentication + '/company',
	COMPANY_ATTRIBUTE: authentication + '/company/companyAttribute',
	RECEIPT_CONTENT: authentication + '/receiptContent',
	VALIDATE_ONLINE_PAYMENT: validateOnlinePayment + '/validateOnlinePayment',
	BILLING_PAYMENT: validateOnlinePayment + '/makeBillingPayment',
	SPONSORED_ADS: sponsoredAds,
	REDIRECT_URL: authentication + '/consumer/payment/operator/redirectUrl',
	DUPLICATE_VALIDATION: authentication + '/permit/allocation/validateMatch',
	REVERT_PERMIT_PRICE: authentication + '/permit/permit-master/revert/permitMasterPricing',
	REVERT_PASS_PRICE: authentication + '/pass/passMaster/revert/passMasterPricing',
	REVERT_PERMIT_PRICE_ALLOCATION_DETAILS: authentication + '/permit/permit-allocation/getPriceUpdateEffectedAllocationDetails',
	REVERT_PASS_PRICE_ALLOCATION_DETAILS: authentication + '/pass/allocation/getPriceUpdateEffectedAllocationDetails',
	UPDATE_TTO: authentication + '/parking/updateTTO',
	LINK_LANES: authentication + '/lanePair',
	PRORATION_API:   '/authentication/v2/permit/prorated-cost/',
	PERMIT_ALLOCATION: '/authentication/v2/permitAllocation/purchase/operator'

}
