import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
// import 'antd/dist/antd.less'
import registerServiceWorker from './registerServiceWorker'
import mqtt from '@utils/mqtt'
import ErrorBoundary from '@pages/ErrorBoundary/ErrorBoundary'
import { Spin } from 'antd'
import * as Sentry from  "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"

//fetch master config
import fetchMasterConfig from '@utils/fetchMasterConfig'
window.latestBuild = process.env.REACT_APP_LATEST_BUILD ? new Date(process.env.REACT_APP_LATEST_BUILD).toISOString() : null

Sentry.init({
	dsn:'https://2f252ad8e6144749bd5139ccb19a2c62@o1323323.ingest.us.sentry.io/6708891',
	  integrations: [new BrowserTracing()],
	  environment:process.env.REACT_APP_DEPLOYMENT,
	  release:'dashboard_2_0',
	  tracesSampleRate: 1.0,
});

const MqttConnector = (props) =>{
	// connect to mqtt broker over websocket
	const { REACT_APP_MQTT_URL, REACT_APP_MQTT_USERNAME, REACT_APP_MQTT_PASSWORD } = process.env
	try {
        if(REACT_APP_MQTT_URL) {
            mqtt.connectToBroker(
                REACT_APP_MQTT_URL,
                {
                    username: REACT_APP_MQTT_USERNAME,
                    password: REACT_APP_MQTT_PASSWORD,
                    port: 61619,
                    reconnectPeriod: 5000
                })
        }
    } catch(e) {}
	return props.children
}

//dynamically import App through lazy loading
const App = React.lazy(() => import('./App' /* webpackChunkName: "APP " */)) 

fetchMasterConfig()
	.then(res => {
		ReactDOM.render(
			<MqttConnector>
				<ErrorBoundary>
					<Suspense
						fallback={
							//fallback UI till App is loaded
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<Spin size='large'></Spin>
							</div>
						}
					>
						<App />
					</Suspense>
				</ErrorBoundary>
			</MqttConnector>,
			document.getElementById('root'),
		)
	})
	.catch(e => {
		console.error(e)
	})
registerServiceWorker()
