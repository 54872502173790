import clevertap from 'clevertap-web-sdk'

let isClevertap=false

export const initClevertap=(clevertapId)=>{
    clevertap.privacy.push({optOut: false}) 
    clevertap.privacy.push({useIP: true})  
    clevertap.init(clevertapId) 
}

export const handleAnalytics=(masterConfig)=>{
    isClevertap = masterConfig?.analytics?.isClevertap
    const clevertapId = masterConfig?.analytics?.clevertapId
    
        if(isClevertap){
            initClevertap(clevertapId)
        }

}

const fireEvents=(event,finalData)=>{
	if (!window.location.origin.includes('localhost')) {
		if (isClevertap) {
			clevertap.event.push(event, finalData)
		}
		// console.log(event, finalData)
	}
}

export const intializeAnalyticsId=(id,email,name)=>{
		if (!id) {
			return
		}
		if (isClevertap) {
			clevertap.onUserLogin.push({
				Site: {
					Name: name,
					Identity: `op_${id}`,
					Email: email,
				},
			})
		}
}

export const liveLaneRetriggerRead = (data) => {
	fireEvents('Live_Lane_Retrigger_Read', data)
}

export const liveLaneOpenGateForFree = (data) => {
	fireEvents('Live_Lane_Open_Gate_For_Free', data)
}

export const liveLaneOpenGateForFreeWithValidation = (data) => {
	fireEvents('Live_Lane_Open_Gate_For_Free_With_Validation', data)
}

export const liveLaneNavCSV2 = (data) => {
	fireEvents('Live_Lane_Nav_CSV2', data)
}