import variables from './tenantConfig'
import uuid from 'uuid-random'
import CryptoJS from 'crypto-js'
import { message, Modal } from 'antd'
import endpoints from './constants/endpoints'
import { createBrowserHistory } from 'history'
import mqtt from '@utils/mqtt'
import { handleAnalytics } from '@utils/analyticsHandler'

import Cookies from 'universal-cookie'

export const cookies = new Cookies()

//const eliteParkingLogo = '/logos/elite-logo.png'
//const spacesLogo = '/logos/spaces-logo.png'
const gmpLogo = '/logos/gmp-logo-white.png'
//const apcoaLogo = '/logos/apcoa-logo.png'
const gmpFevicon = '/logos/gmp-favicon.ico'
// const apcoaFevicon = '/logos/apcoa-favicon.ico'
// const oneparkingFavicon = '/logos/oneparking-favicon.png'
// const oneparkingLogo = '/logos/oneparking-logo.png'
// const garageinnLogo = '/logos/garageinn-logo.png'
// const garageinnFavicon = '/logos/garageinn-fevicon.png'
// const greenarkingFevicon = '/logos/greenparking-fevicon.png'
// const greenparkingLogo = '/logos/greenparking-logo.png'
// const bacFavicon = '/logos/bac-favicon.png.png'
// const bacLogo = '/logos/bac-logo.png'

const defaultThemeValues = {
	themeColor: '#1890ff',
	icon: gmpFevicon,
	logoUrl: gmpLogo,
}

const { REACT_APP_TENANT: X_GMP_TENANT, REACT_APP_DEPLOYMENT: deployment, REACT_APP_HMAC_SALT: salt } = process.env

const { API_URL } = variables

const queryString = params =>
	Object.keys(params)
		.map(key => {
			const value = Array.isArray(params[key]) ? params[key].join(',') : params[key]
			return key + '=' + value
			// return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
		})
		.join('&')

export const HMACGet = async (endpoint, data, { avoidErrorNotification } = {}) => {
	try {
		const requestWithQueries = data && !!Object.keys(data).length
		const httpQuery = requestWithQueries ? '?' + queryString(data) : ''

		let request = await fetch(API_URL + endpoint + httpQuery, { headers: HMACHeaders('GET', endpoint) })
		const response = await checkWithErrors(request)

		return response
	} catch (e) {
		if (!avoidErrorNotification) {
			message.error(e.message)
		}
		throw new Error()
	}
}

export const checkWithErrors = async (req, contentType = 'json') => {
	try {
		const { status } = req
		if (status && Math.round(status / 100) !== 2) {
			if (status === 419) {
				//localStorage.clear()
				sessionStorage.clear()
				let reqStorage = ['checkedColumns', 'dropdownItems', 'idData', 'pwdExpiryDate']
				Object.keys(localStorage)
					.filter(item => !reqStorage.includes(item))
					.forEach(key => {
						if (!key.includes('checkedColumns') || !key.includes('dropdownItems')) delete localStorage[key]
					})
				
				cookies.remove('token',{path:'/'})
				const history = createBrowserHistory()
				localStorage.setItem('lastVisited', JSON.stringify(history.location))
				window.location.reload()

				// await new Promise(() => {
				// 	localStorage.setItem('lastVisited', JSON.stringify(history.location))
				// 	window.location.reload()
				// 	/* https://getmyparking.atlassian.net/browse/APD-7635
				// 	Modal.warning({
				// 		title: 'Opss.! Session Expired',
				// 		content: `Looks like you came back after a long time your session is expired. Please login again to continue using the portal. This page will reload automatically in 60 seconds`,
				// 		okText: 'Login again',
				// 		onOk: () => {
				// 			window.location.reload()
				// 		},
				// 		keyboard: false,
				// 	})
				// 	*/
				// 	setTimeout(() => {
				// 		window.location.reload()
				// 	}, 60000)
				// })
			}
			if (status === 404) {
				const res = contentType === 'json' ? await req.json() : await req.arrayBuffer()
				throw new Error(res.message || 'Not Found')
			}

			try {
				const res = contentType === 'json' ? await req.json() : await req.arrayBuffer()
				throw new Error(res.message || 'Something went wrong')
			} catch (e) {
				throw new Error(e.message || 'Something went wrong')
			}
		}

		try {
			const response = status === 204 ? { status: 'OK' } : req.json()
			return response
		} catch (e) {
			return { success: 'OK' }
		}
	} catch (e) {
		mqtt.publishToBroker(req.url + e.message)
		throw new Error(e.message)
	}
}

export const HMACHeaders = (method, endpoint) => {
	const date = new Date().toGMTString('EEE, dd MMM YYYY HH:mm:ss zzz')
	const signature = `x-date: ${date}\n${method} ${endpoint} HTTP/1.1\nx_gmp_tenant: ${X_GMP_TENANT}`
	const hash = CryptoJS.HmacSHA256(signature, salt)
	const h = CryptoJS.enc.Base64.stringify(hash).toString()
	return {
		'content-type': 'application/json',
		'x-date': date,
		X_DEVICE_ID: 'Web',
		X_GMP_TENANT: X_GMP_TENANT,
		Authorization: `hmac username="${X_GMP_TENANT}", algorithm="hmac-sha256", headers="x-date request-line x_gmp_tenant", signature="${h}"`,
		X_GMP_CORRELATION_ID: uuid(),
	}
}

export default () => {
	return new Promise(async (resolve, reject) => {
		try {
			// let entityId = 'https://dashboard.flow-connect.net'
			// let entityId = 'https://poc-dashboard-test.getmyparking.com'

			let entityId = window.location.origin
			//  let entityId = 'https://partner-portal-v1-qa.getmyparking.com'
			// let entityId = 'https://gmpone-admin-db.getmyparking.com'
			// let entityId='https://dashboard.flow-connect.net'
			// let entityId = 'https://gmpone-admin-dev.getmyparking.com'

			// let entityId = 'https://gmpdev-admin-dev.getmyparking.com'

			// let entityId = 'https://develop.d2gadkpif0htk5.amplifyapp.com'

			const response = await HMACGet(endpoints.MASTER_CONFIG, { attributeType: 'WEB_CONFIG', entityType: 'URL', entityId: entityId })
			let configData = {
				...defaultThemeValues,
				...response[0].attributeData,
				API_URL: response[0].attributeData.API_URL[deployment],
				SOCKET_URL: response[0].attributeData.SOCKET_URL[deployment],
				deployment,
				salt,
				gatewaySecretKey: response[0].attributeData.gatewaySecretKey,
				gatewayEnv: response[0].attributeData.gatewayEnv,
				companyLogo: response[0].attributeData.logoUrl,
				companyIcon: response[0].attributeData.icon,
				companyId: response[0].companyId,
			}

			handleAnalytics(configData)

			let isClarity = response.length && response[0].attributeData?.isClarity;

			if (isClarity) {
				initClarity(response)
			}

			if (response) {
				cookies.set('masterConfig', configData)
				//modify the antd colors
				window.less.modifyVars({
					'@primary-color': configData.themeColor,
					'@btn-primary-bg': configData.themeColor,
					'@link-color': configData.themeColor,
				})
				if (configData?.isGitbookDisabled) {
					localStorage.setItem('isGitbookDisabled', JSON.stringify(configData?.isGitbookDisabled))
				}
				return resolve(response)
			}
			return reject()
		} catch (e) {
			console.error(e)
			return reject(e)
		}
	})
}

export const initClarity = (response)=>{
	const clarityId = response.length && response[0].attributeData?.clarityId
	intializeClarity(clarityId)
	
}

export const intializeClarity = clarityId => {
	;(function (c, l, a, r, i, t, y) {
		c[a] =
			c[a] ||
			function () {
				;(c[a].q = c[a].q || []).push(arguments)
			}
		t = l.createElement(r)
		t.async = 1
		t.src = 'https://www.clarity.ms/tag/' + i
		y = l.getElementsByTagName(r)[0]
		y.parentNode.insertBefore(t, y)
	})(window, document, 'clarity', 'script', clarityId)
}